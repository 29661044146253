
import { reactive, onMounted, computed, ref } from 'vue';
import {Actions} from '@/store/enums/SupplierOrderEnums.ts';
import {Actions as SupplierActions} from '@/store/enums/SupplierEnums.ts';
import {Actions as OrderStatusActions} from '@/store/enums/OrderStatusEnums.ts';
import {Actions as UserActions} from '@/store/enums/UserEnums.ts';
import {Actions as CurrencyActions} from '@/store/enums/CurrencyEnums.ts';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  name : 'SupplierOrderAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Order Add",to : "/orders/supplier/add"}
      ]);

      store.dispatch(SupplierActions.ALL_SUPPLIERS);
      store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);
      store.dispatch(UserActions.GET_USERS);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    });

    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false);

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      supplier_id : null,
      orderDate : null,
      reference : '<auto>',
      order_status_id : null,
      user_id : JSON.parse(JSON.stringify(store.getters.currentUser.id)),
      currency_id : null
    })

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_SUPPLIER_ORDER,formData).then((response) => {

        if(response.success){
          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/orders/supplier/edit/'+response.order_id)
          },1000)
        }

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      errors,
      formData,
      submitForm,
      submitButton,
      store,
      router,
      success
    }
  }
}
