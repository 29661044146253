<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Supplier Order Add</h3>
        </div>

      </div>
      <div class="card-body p-7">
        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully created supplier order!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Supplier</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.supplier_id}" v-model="formData.supplier_id">
              <option value="null" selected disabled>Select Supplier</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myAllSuppliers" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.supplier_id">{{errors.value && errors.value.supplier_id[0]}}</span>
          </div>
          <div class="col-12 col-lg-6">
            <label class="col-form-label required fw-bold fs-6">Order Date</label>
            <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.orderDate}" v-model="formData.orderDate">
            <span class="text-danger" v-if="errors.value && errors.value.orderDate">{{errors.value && errors.value.orderDate[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Reference</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.reference}" v-model="formData.reference">
            <span class="text-danger" v-if="errors.value && errors.value.reference">{{errors.value && errors.value.reference[0]}}</span>
          </div>
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Order Status</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.order_status_id}" v-model="formData.order_status_id">
              <option value="null" selected disabled>Select Status</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myOrderStatuses" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.order_status_id">{{errors.value && errors.value.order_status_id[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Made By</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.user_id}" v-model="formData.user_id">
              <option value="null" selected disabled>Select User</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myUsers" :key="key">{{item.first_name+' '+item.last_name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.user_id">{{errors.value && errors.value.user_id[0]}}</span>
          </div>
          <div class="col-12 mb-3 col-lg-6 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">PO Currency</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="formData.currency_id">
              <option value="null" selected disabled>Select Currency</option>
              <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
          </div>
        </div>

      </div>

      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import {Actions} from '@/store/enums/SupplierOrderEnums.ts';
import {Actions as SupplierActions} from '@/store/enums/SupplierEnums.ts';
import {Actions as OrderStatusActions} from '@/store/enums/OrderStatusEnums.ts';
import {Actions as UserActions} from '@/store/enums/UserEnums.ts';
import {Actions as CurrencyActions} from '@/store/enums/CurrencyEnums.ts';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  name : 'SupplierOrderAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Order Add",to : "/orders/supplier/add"}
      ]);

      store.dispatch(SupplierActions.ALL_SUPPLIERS);
      store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);
      store.dispatch(UserActions.GET_USERS);
      store.dispatch(CurrencyActions.GET_CURRENCIES);
    });

    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false);

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      supplier_id : null,
      orderDate : null,
      reference : '<auto>',
      order_status_id : null,
      user_id : JSON.parse(JSON.stringify(store.getters.currentUser.id)),
      currency_id : null
    })

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_SUPPLIER_ORDER,formData).then((response) => {

        if(response.success){
          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/orders/supplier/edit/'+response.order_id)
          },1000)
        }

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      errors,
      formData,
      submitForm,
      submitButton,
      store,
      router,
      success
    }
  }
}
</script>
